<template>
    <div class="change-phone-number">
        <div class="page-header">
            <StackRouterHeaderBar />
            <div class="title" v-html="$translate('CHANGE_PHONE_NUMBER') + '하기'" />
        </div>
        <div class="body">
            <div class="comment" v-html="$translate('CHANGE_PHONE_NUMBER_COMMENT')" />
            <img class="example" src="@/assets/images/change-phone-number.png" />
        </div>
        <div class="btn-wrapper">
            <button
                class="btn btn-primary btn-submit"
                v-html="$translate('CHANGE_PHONE_NUMBER_SUBMIT')"
                @click="onClickSubmitButton"
            />
        </div>
    </div>
</template>

<script>
import verificationBadgeService from '@/services/verification-badge'

export default {
    name: 'ChangePhoneNumberPage',
    data: () => ({}),
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                const checkGender = niceGender => {
                    // nice => female '0' male '1' // vb => male 0 female 1
                    const vbGender = this.$store.getters.me.gender
                    return !(Number(niceGender) === vbGender)
                }

                if (checkGender(newVal.gender)) this.finishVerification(newVal)
                else {
                    this.$modal.basic({
                        title: '전화번호 변경에 실패했어요',
                        body: '가입시 성별과 달라요. 전화번호를 변경하려면 카카오톡 플러스 친구에 문의 주세요!',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-default',
                            },
                        ],
                    })
                }
            },
            deep: true,
        },
    },
    computed: {},
    mounted() {
        this.init()
    },
    methods: {
        async init() {},
        async onClickSubmitButton() {
            try {
                await this.submitRealNameVerify()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },

        async submitRealNameVerify() {
            this.$nativeBridge.postMessage({ action: 'verification' })
        },

        async finishVerification(niceData) {
            const responseId = niceData.response_id
            const payload = this.preparedPayload()
            payload.append('response_id', responseId)

            try {
                await verificationBadgeService.create(this.$store.getters.me.id, payload)
                this.$toast.success('휴대폰 번호가 변경되었어요!')
                await this.$store.dispatch('loadMyVerificationBadges')
            } catch (e) {
                this.$modal.basic({
                    title: '휴대폰 번호 변경 실패',
                    body:
                        '다시 한번 시도해주세요. 만약 직접 변경이 어려우시다면 카카오톡 플러스친구 "바닐라브릿지"에 문의주세요!',
                    buttons: [
                        {
                            label: '확인',
                            class: 'btn-default',
                        },
                    ],
                })
            }

            this.$stackRouter.pop()
        },
        preparedPayload() {
            const payload = new FormData()

            payload.append('verification_badge_id', 19)
            payload.append('verification_type', 'auto')

            return payload
        },
    },
}
</script>

<style lang="scss" scoped>
.change-phone-number {
    line-height: normal;

    .body {
        padding: 16px 20px 80px;
        overflow-y: scroll;

        .comment {
            font-size: 16px;
        }

        .example {
            width: 100%;
            padding: 32px 20px;
            // padding: 0px 20px;
            // margin: 32px 0px;
            // max-height: 370px;

            // width: 280px;
            // height: 370px;
            // margin-top: 32px;
        }
    }

    .page-title {
        margin: 0 0 32px;
        font-size: 24px;
        color: black;
        @include f-medium;
    }

    .btn-wrapper {
        position: fixed;
        bottom: 0;
        width: 100vw;
        padding: 16px 20px;
        border-top: solid 1px $grey-02;
        background-color: white;
        @include center;

        .btn-submit {
            width: 100%;
            height: 48px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(84, 72, 203, 0.1);
        }
    }
}
</style>
